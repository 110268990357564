<template>
    <div class="invite-form">
      <el-form-item
        :label="label">
        <el-input v-model="email"  v-if="!readonly" placeholder="Email address"></el-input>
      </el-form-item>
      <ActionItemComponent
        v-if="!readonly"
        label="Add"
        icon="add-circle-yellow.svg"
        fontColor="#FFA500"
        @call-to-action="onInvite"/>
    </div>
</template>
<script>
import { defineComponent } from 'vue';

import ActionItemComponent from '@/core/components/ui/ActionItemComponent.vue';

export default defineComponent({
  components: {
    ActionItemComponent
  },
  emits: ['on-invite'],
  props: {
    label: {
      type: String,
      default: 'Email'
    },

    readonly: {
      type: Boolean,
      default: false
    },

    user: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      email: '',
    };
  },

  computed: {
    userEmail() {
      const { email } = this.user;
      return email;
    }
  },

  mounted() {
    this.setDefaultEmail(this.userEmail);
  },

  methods: {
    setDefaultEmail(userEmail) {
      this.email = userEmail;
      this.onInvite();
    },

    onInvite() {
      this.$emit('on-invite', this.email);
      this.email = '';
    }
  },
});
</script>
<style lang="scss" scoped>
.invite-form {
  .el-form-item {
      margin: 0;

      :deep(.el-form-item__label) {
          font-family: Mulish;
          font-size: 16px;
          font-weight: 700;
          line-height: 25px;
          letter-spacing: 0.25px;
          text-align: left;
          color: #0C0F4A;
      }

      :deep(.el-input) {
        width: 100%;
        border-radius: 100px;
        height: 42px;
        background: rgba(12, 15, 74, 0.05);

        .el-input__inner {
          border-radius: 100px;
          outline:none;
          border: none;
          padding: 20px;
          background: transparent;
        }
      }

    :deep(.el-input__suffix) {
      right:20px;
      top: 5px;
      color:#111;
      display: inline-block !important;
    }
  }
}
</style>
