<template>
  <BaseModal
    :show="show"
    :showCancelBtn="false"
    :showSubmitBtn="false"
    :showDefaultCloseBtn="false"
    @cancel="cancel"
    :hasActions="false"
    >
    <div class="create-event-form flex-column jc-sb">
        <div class="create-event-form__container flex-column">
          <div class="create-event-form__header flex-row ai-c jc-sb">
              <h2 class="create-event-form__title default-text">Create new event</h2>
              <inline-svg class="cursor-pointer" @click="cancel" :src="require(`@/assets/icons/x.svg`)"></inline-svg>
          </div>
          <div class="create-event-form__body">
            <el-form
             class="create-event-form__form flex-column"
             ref="createEventForm"
             :model="eventForm"
             label-position="top">
              <div class="create-event-form__form-container flex-column jc-sb">
                  <section class="create-event-form__form-container-content flex-column">
                    <el-form-item
                        label="Event name">
                        <el-input :disabled="isReadOnly" autofocus placeholder="E.g. Site viewing" v-model="eventForm.title"></el-input>
                    </el-form-item>
                    <el-form-item label="Date">
                      <el-date-picker
                        :disabled="isReadOnly"
                        v-model="eventForm.schedule.date"
                        type="date"
                        :format="DATE_FORMAT"
                        :value-format="'YYYY-MM-DD'"
                        placeholder="E.g. 10/10/2022"
                        :disabledDate="disabledDate"
                      />
                    </el-form-item>
                    <el-form-item
                        label="Time">
                      <div class="create-event-form__time-container">
                        <el-time-select
                          :disabled="isReadOnly"
                          v-model="eventForm.time.startTime"
                          :max-time="eventForm.time.endTime"
                          placeholder="Start time"
                          start="07:00"
                          step="00:15"
                          end="23:30"
                        />
                        <el-time-select
                          :disabled="isReadOnly"
                          v-model="eventForm.time.endTime"
                          :min-time="eventForm.time.startTime"
                          placeholder="End time"
                          start="07:00"
                          step="00:15"
                          end="23:30"
                        />
                      </div>
                    </el-form-item>
                    <el-form-item
                        label="Location">
                        <el-input :disabled="isReadOnly" placeholder="E.g. SW9 6DE" v-model="eventForm.address"></el-input>
                    </el-form-item>
                    <el-form-item
                        label="Notes">
                        <el-input :disabled="isReadOnly" type="textarea" placeholder="Note.." v-model="eventForm.note" clearable></el-input>
                    </el-form-item>
                    <InviteEmailForm :readonly="isReadOnly" @on-invite="addToMemberList($event)" :user="user"/>
                    <div class="create-event-form__wrapper flex-row ai-c jc-sb" v-for="(recipient, index) in eventForm.recipients" :key="index">
                      <p class="create-event-form__text default-text">{{ recipient?.email }}</p>
                      <ActionItemComponent v-if="!isReadOnly" icon="trash-icon.svg" @call-to-action="removeEmailToList(index)"/>
                    </div>
                  </section>
                  <section class="create-event-form__actions">
                    <Button v-if="!isReadOnly" :isActive="isFormReadyToSubmit" :buttonText="viewSchedule ? 'update' : 'create'" :loading="loading" @handle-click="onRequest"/>
                    <Button v-else :isActive="true" buttonText="OK"  @handle-click="cancel"/>
                  </section>
               </div>
            </el-form>
          </div>
        </div>
    </div>
  </BaseModal>
</template>
<script>
import _ from 'lodash';
import moment from 'moment';
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import InviteEmailForm from '@/core/components/common/forms/request-meeting/InviteEmailForm.vue';
import ActionItemComponent from '@/core/components/ui/ActionItemComponent.vue';
import BaseModal from '@/core/components/ui/BaseModal.vue';
import Button from '@/core/components/ui/Button.vue';
import { CALENDAR_EVENT_TYPE_CALENDAR, CALENDAR_EVENT_TYPE_PROPERTY, DATE_FORMAT } from '@/core/constants';
import { GENERIC_ERROR_MESSAGE } from '@/core/constants/messages';
import StringHelper from '@/core/helpers/string.helper';
import { USER_CALENDAR_STORE } from '@/store/modules/user-calendar';
import { USERS_STORE } from '@/store/modules/users';

export default defineComponent({
  components: {
    BaseModal,
    Button,
    InviteEmailForm,
    ActionItemComponent
  },
  emits: ['on-cancel'],
  props: ['show', 'viewSchedule', 'type'],
  data() {
    return {
      DATE_FORMAT,

      isFormReadyToSubmit: false,
      loading: false,
      eventForm: {
        note: '',
        title: '',
        address: '',
        recipients: [],
        schedule: {
          date: '',
          timeslots: []
        },
        time: {
          startTime: '',
          endTime: '',
        }
      },

      defaultForm: null,
      isReadOnly: false,

      disabledDate(date) {
        return date < moment(new Date()).set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0
        }).toDate();
      },
    };
  },
  watch: {
    eventForm: {
      immediate: true,
      deep: true,
      handler() {
        const {
          note,
          title,
          address,
          recipients,
          time,
          date
        } = this.eventForm;

        if ((note !== '' && title !== '' && address !== '' && recipients.length)
          && (time.startTime !== '' && time.endTime !== '') && date !== '') {
          this.isFormReadyToSubmit = true;
        } else {
          this.isFormReadyToSubmit = false;
        }
      }
    },
    viewSchedule: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value) {
          const {
            id,
            title,
            address,
            date,
            timeslots,
            note,
            recipients
          } = value;
          const [timeSchedule] = timeslots;

          // we load the form
          this.eventForm = {
            id,
            note,
            title,
            address,
            recipients,
            schedule: {
              date,
              timeslots
            },
            time: {
              startTime: timeSchedule.startTime,
              endTime: timeSchedule.endTime
            }
          };

          this.isReadOnly = false;
        } else {
          this.resetForm();
        }
      }
    },
  },

  computed: {
    ...mapGetters(USERS_STORE, ['user']),
  },

  created() {
    this.resetForm();
  },

  methods: {
    ...mapActions(USER_CALENDAR_STORE, ['createOrUpdateScheduleRequest']),

    resetForm() {
      const { eventForm, defaultForm } = this;
      this.isReadOnly = false;

      if (!defaultForm || defaultForm === null) {
        this.defaultForm = _.cloneDeep(eventForm);
      }

      this.eventForm = this.defaultForm;
    },

    async onRequest() {
      const formData = _.cloneDeep(this.eventForm);
      const { schedule, time } = formData;
      const { date } = schedule;
      const userId = this.user.id;
      const eventType = this.type || CALENDAR_EVENT_TYPE_CALENDAR;
      let metaId = userId;

      // include a datetime
      time.startDatetime = `${date} ${time.startTime}:00`;
      time.endDatetime = `${date} ${time.endTime}:00`;

      schedule.timeslots.push({ ...time });

      formData.schedule = [schedule];

      delete formData.time;

      // if property get the property id
      if (eventType === CALENDAR_EVENT_TYPE_PROPERTY) {
        metaId = this.$route.params?.id;
      }

      const params = {
        userId,
        formData: { ...formData, type: eventType, meta: { id: metaId } }
      };

      this.loading = true;

      await this.createOrUpdateScheduleRequest(params)
        .then(async (response) => {
          if (response) {
            this.eventForm = {
              note: '',
              title: '',
              address: '',
              recipients: [],
              schedule: {
                date: '',
                timeslots: []
              },
              time: {
                startTime: '',
                endTime: '',
              }
            };
            this.$notify.success({
              title: 'Request sent.',
              message: 'Event successfully sent.',
            });
            this.$emit('on-cancel', true);
          }
        })
        .catch(() => {
          this.$notify.error({
            title: 'Event Creation Failed',
            message: GENERIC_ERROR_MESSAGE,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },

    cancel() {
      this.$emit('on-cancel');
    },

    addToMemberList(email) {
      this.$refs.createEventForm.validate((valid) => {
        let isValid = valid;

        if (!StringHelper.isValidEmail(email)) {
          this.$notify.error({
            message: 'Please enter a valid email!'
          });
          isValid = false;
        }

        const emailExist = this.eventForm.recipients.find((emailObj) => emailObj.email === email);

        if (emailExist) {
          this.$notify.error({
            message: 'Email already exist!'
          });
          isValid = false;
        }

        if (isValid) {
          this.eventForm.recipients.push({
            email
          });
        }
      });
    },
    removeEmailToList(index) {
      this.eventForm.recipients.splice(index, 1);
    },
  },
});
</script>
<style lang="scss" scoped>
@use "../../../../assets/scss/mixins/media-query" as *;
@use "../../../../assets/scss/mixins/" as *;

 .create-event-form {
    min-width: 500px;
    min-height: 700px;
    position: relative;
    height: 700px;

    &__time-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
    }

    &__container {
      gap: 1.5rem;
      height: 100%;
    }

    &__body {
      height: calc(100% - 3rem);
    }

    &__form {
        height: 100%;
    }

    &__form-container {
        height: 100%;
    }

    &__form-container-content {
        overflow-y: auto;
        .el-form-item {
            margin: 0;

            :deep(.el-form-item__label) {
                font-family: Mulish;
                font-size: 16px;
                font-weight: 700;
                line-height: 25px;
                letter-spacing: 0.25px;
                text-align: left;
                color: #0C0F4A;
            }

            :deep(.el-input) {
                width: 100%;
                border-radius: 100px;
                border: none;
                height: 42px;
                background: rgba(12, 15, 74, 0.05);

                .el-input__inner {
                border-radius: 100px;
                outline:none;
                border: none;
                padding: 20px;
                background: transparent;
                }
            }

            :deep(.el-input__suffix) {
              right:20px;
              top: 5px;
              color:#111;
              display: inline-block !important;
            }
            :deep(.el-input__prefix) {
              right:15px;
              left: initial;
            }
            :deep(.el-textarea__inner) {
              background: rgba(12, 15, 74, 0.05);
              border: none;
            }
            :deep(.el-range-editor.el-input__inner) {
              width: 100%;
              border-radius: 100px;
            }
        }
    }

    &__title {
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.15000000596046448px;
    }

    &__actions {
        width: 100%;
        height: auto;
    }

    &__button {
        height: 42px;
        width: 100%;
        border-radius: 8px;
        background: rgba(12, 15, 74, 0.05);
        font-size: 14px;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: 1.25px;
        text-align: center;
        color: rgba(12, 15, 74, 0.5);
        border: none;
        text-transform: uppercase;

        &:hover {
            background: rgba(12, 15, 74, 0.05);
            color: rgba(12, 15, 74, 0.5);
        }
    }

    &__button.active {
        color: #FFF;
        background-color: #FFA500;

        &:hover {
          color: #FFF;
          background-color: #FFA500;
        }
    }
 }

 @include media-sm-max-width() {
   .create-event-form {
    min-height: auto;
    height: auto;
    min-width: 400px;

    &__container {
      gap: 0.5rem;
    }

    &__form-container-content {
      gap: 0.5rem;
      max-height: 500px;
    }

    &__time-container {
      grid-template-columns: 1fr;
      gap: 0.5rem;
    }
   }
 }

 @include media-xs-max-width() {
   .create-event-form {
    min-width: 300px;
   }
 }
</style>
